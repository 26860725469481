import AppConfig from 'Constants/AppConfig';

export const OnboardingRoute = Object.freeze(
  // Property keys are named based on their respective components
  // Route names and path can be changed as required.
  {
    Signup: {
      name: 'signup',
      path: '/signup'
    },
    SignupUser: {
      name: 'signupUser',
      path: '/signup/user'
    },
    SetupCreativeBuilder: {
      name: 'setupCreativeBuilder',
      path: '/signup/setupCreativeBuilder'
    }
  });

export const OnboardingType = Object.freeze({
  Default: 0
});

export const OnboardingRouteConfig = Object.freeze({
  0: [
    OnboardingRoute.SignupUser,
    OnboardingRoute.SetupCreativeBuilder
  ]
});

export const OnboardingImage = Object.freeze({
  StoreDetails: `${AppConfig.staticImagesBasePath}onboarding/store-link-successful.png`,
  SignupUser: `${AppConfig.staticImagesBasePath}onboarding/signup.png`
});

