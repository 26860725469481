// router
import router from './router';
// onboarding routes
import { OnboardingRoute } from './features/onboarding/constants';
import { PageNotFoundRoute } from './router/pageNotFound';
import { RoimaticService } from 'AppServices/roimaticService';
import { LoginRoute, ProfileSelectorRoute } from './features/login/router';
import { AppService } from 'AppServices/appService';
import { BusinessProfilesHelper } from 'Helpers/businessProfilesHelper';
import { AuthService } from 'AppServices/authService';
const { getPortalData } = new AuthService();
import { getPortalDomain } from './store/modules/auth/index.js';
const { getCurrencyInfo, getPortalBusinessProfiles } = new AppService();
import Vue from 'vue';

const  { getBusinessProfiles } = new BusinessProfilesHelper();

const isBpLevelRoute = (destinationRoute) => {
  return (
    destinationRoute.path.startsWith('/app')
  );
};

const getCookie = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieParts = cookie.split('=');
    if (cookieParts.length === 2 && cookieParts[0] === name) {
      return decodeURIComponent(cookieParts[1]);
    }
  }
  return null;
}

export const GlobalRouterGuard = {
  configure(nProgress, i18n, store) {
    const redirect = (from, redirectRoute, next) => {
      // Call NProgress.done() for cancelled navigations
      if (from.name === redirectRoute.name) {
        nProgress.done();
      }
      next(redirectRoute);
    };

    // navigation guards before each
    router.beforeEach(async (to, from, next) => {
      nProgress.start();
      // We do not want roimatic router guard logic on navigation between every CB route
      if (to.name
        && to.name.startsWith('creativeBuilder')
        && from.name
        && from.name.startsWith('creativeBuilder')
        && (to.params.businessProfileId == from.params.businessProfileId)
      ) {
        next();
        nProgress.done();
        return;
      }

      if(to.name == PageNotFoundRoute.name){
        next();
        return;
      }

      await store.dispatch('setPortal');
      // If portal is not set, redirect to NotFoundPage
      if(!store.state.auth.portal){
        let redirectRoute = {
          name: PageNotFoundRoute.name,
          params: [from.path], // https://github.com/vuejs/vue-router/issues/724#issuecomment-663315290
          replace: true
        };
        redirect(from, redirectRoute, next);
      }
      // Out of the matched routes find the title in the meta of the closest route,
      // The closest route is the last route in a nested route
      var nearestRouteMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

      let portalTitle = 'Stunnr';

      document.title = nearestRouteMeta
        ? `${portalTitle} | ${i18n.t(nearestRouteMeta.meta.title)}`
        : portalTitle;

      // Setting bpId as null to all non bp level routes
      if (!isBpLevelRoute(to)) {
        await store.dispatch('setBusinessProfileId', null);
      }

      if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        let user = Vue.$cookies.get('user');
        let accessToken = Vue.$cookies.get('accessToken')?.accessToken;
        if (user) {
          store.dispatch('setUser', user);
        }
        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
        }
        if (
          !user || !accessToken ||
                    (!to.path.startsWith('/app') &&
                        !(to.name == ProfileSelectorRoute.name) &&
                        !to.path.startsWith(OnboardingRoute.Signup.path))
        ) {
          if (!accessToken) {
            // removing user if accesstoken is missing to prevent endless redirect
            Vue.$cookies.remove('user', '/', process.env.VUE_APP_STUNNR_DOMAIN);
            localStorage.removeItem('accessToken');
          }

          let redirectRoute = {
            name: LoginRoute.name,
            query: { redirect: to.fullPath }
          };
          redirect(from, redirectRoute, next);
        } else if (isBpLevelRoute(to)) {
          let selectedBusinessProfile;
          let isBpIdInValid = isNaN(to.params.businessProfileId);

          if (!isBpIdInValid) {
            // /app is the AppLayout hence it has to include businessProfileId in the path
            let businessProfileId = parseInt(
              to.params.businessProfileId
            );
            const portalDomain = getPortalDomain();
            const portalData = await getPortalData(portalDomain);
            const portalBusinessProfiles = await getPortalBusinessProfiles(user.id, portalData.id);
            selectedBusinessProfile = portalBusinessProfiles.find(
              (bp) => bp.id === businessProfileId
            );
            store.dispatch('setPortalBusinessProfiles', portalBusinessProfiles);
          }

          if (!selectedBusinessProfile) {
            let redirectRoute = {
              name: PageNotFoundRoute.name
            };
            redirect(from, redirectRoute, next);
            return;
          }
          // Get roimatic configuration for the businessProfile
          let roimaticService = new RoimaticService(
            selectedBusinessProfile.id
          );
          let roimaticConfiguration = await roimaticService.getRoimaticConfiguration();
          await store.dispatch(
            'setRoimaticConfiguration',
            roimaticConfiguration
          );
          await store.dispatch(
            'setBusinessProfileId',
            selectedBusinessProfile.id
          );

          if (selectedBusinessProfile.currencyId == 0 || !selectedBusinessProfile.currencyId)
          {
            var businessProfiles = await getBusinessProfiles(true);
            user.businessProfiles = businessProfiles;
            selectedBusinessProfile = businessProfiles.find(bp => bp.id == selectedBusinessProfile.id);
          }
          let currencyInfo = await getCurrencyInfo(
            selectedBusinessProfile.currencyId,
            selectedBusinessProfile.id
          );
          await store.dispatch('setCurrencyInfo', currencyInfo);
          await store.dispatch('setCurrencyCode', currencyInfo.code);
          await store.dispatch('setTimezoneId', selectedBusinessProfile.timezoneId);
        }
        next();
      }
      else if (
        to.path.startsWith('/home') &&
          Vue.$cookies.get('user') !== null &&
          Vue.$cookies.get('accessToken')?.accessToken !== null
      ) {
        next({
          name: LoginRoute.name
        });
      }
      else if (to.path.startsWith('/cbRenderer') && process.env.NODE_ENV !== 'development') {
        let redirectRoute = {
          name: PageNotFoundRoute.name,
          params: [from.path],
          replace: true
        };
        const puppeteerSecretKey = getCookie('puppeteerSecretKey');
        if(puppeteerSecretKey !== process.env.VUE_APP_PUPPETEER_SECRET_KEY) {
          redirect(from, redirectRoute, next);
        }
        next();
      }
      // else if (
      //     to.path.startsWith("/home") &&
      //     localStorage.getItem("user") !== null &&
      //     localStorage.getItem("accessToken") !== null
      //   ) {
      //     next({
      //       name: LoginRoute.name
      //     });
      //   }
      else {
        next(); // make sure to always call next()!
      }
    });

    // navigation guard after each
    router.afterEach(() => {
      nProgress.done();
      setTimeout(() => {
        const contentWrapper = document.querySelector(
          '.v-content__wrap'
        );
        if (contentWrapper !== null) {
          contentWrapper.scrollTop = 0;
        }
        const boxedLayout = document.querySelector(
          '.app-boxed-layout .app-content'
        );
        if (boxedLayout !== null) {
          boxedLayout.scrollTop = 0;
        }
        const miniLayout = document.querySelector(
          '.app-mini-layout .app-content'
        );
        if (miniLayout !== null) {
          miniLayout.scrollTop = 0;
        }
      }, 200);
    });

    router.onError(error => {
      if (/loading chunk \w* failed./i.test(error.message)) {
        var queryParameters = new URLSearchParams(window.location.search);
        // The retry query parameter is added to prevent endless reload which might happen in certain cases
        var retry = queryParameters.get('retry');
        if(!(retry == 'true')){
          queryParameters.append('retry', 'true');
          window.location.assign(window.location.origin + window.location.pathname + `?${queryParameters}`);
        }
      }
    })
  }
};