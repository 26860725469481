
import offlineFontInfo from '../customFonts.json';
import customPublishApiFontInfo from '../customPublishApiFonts.json';
export const fontStyles = {
  Bold: 1,
  Italic: 2,
  Underlined: 3,
  Overlined: 4,
  Strikedthrough: 5
}
const standardFonts = [
  {
    'name': 'Arial',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Arial Black',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },

  {
    'name': 'Comic Sans MS',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },

  {
    'name': 'Impact',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },

  {
    'name': 'Charcoal',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },

  {
    'name': 'Lucida Sans Unicode',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },

  {
    'name': 'Lucida Grande',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },

  {
    'name': 'Tahoma',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Trebuchet MS',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },

  {
    'name': 'Verdana',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },
  {
    'name': 'Courier New',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },

  {
    'name': 'Lucida Console',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      },
      {
        'name': 'Bold',
        'value': 700
      }
    ]
  },

  {
    'name': 'Palatino Linotype',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      }
    ]
  },
  {
    'name': 'Book Antiqua',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      }
    ]
  },

  {
    'name': 'Georgia',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      }
    ]
  },
  {
    'name': 'Times New Roman',
    'variants': [
      {
        'name': 'Regular',
        'value': 400
      }
    ]
  }
]
export const fontInfo = offlineFontInfo.concat(standardFonts);
export const fontFamilies = fontInfo.map(font => ({ name: font.name })).sort((a, b) => a.name.localeCompare(b.name));
export const offlineFontFamilies = offlineFontInfo.map(font => font.name).sort();
export const standardFontFamilies = standardFonts.map(font => font.name).sort();
export const publishApiFontInfo = customPublishApiFontInfo.concat(fontInfo);
export const customPublishApiFontFamilies = customPublishApiFontInfo.map(font => ({ name: font.name })).sort((a, b) => a.name.localeCompare(b.name));
export const publishApiFontFamilies = publishApiFontInfo.map(font => ({ name: font.name })).sort((a, b) => a.name.localeCompare(b.name));
export const textBoxPaddingOffset = 0.01
export const textBoxRightProximityFactor = 0.98
export const customFontData = offlineFontInfo.map(font => {
  let variantsInFvdNotation =
    font.variants
      .map(variant => [`n${variant.value.toString()[0]}`, `i${variant.value.toString()[0]}`])
      .reduce((acc, val) => acc.concat(val), []);; // Alternative for flat()
  return {
    name: font.name,
    fvdNotation: `${font.name}:${variantsInFvdNotation.join(',')}`
  };
})

export const textSizes = {
  Heading: 'Heading',
  Subheading: 'Subheading',
  Body: 'Body'
}

export const canvasRelativeTextSizes = {
  Heading: parseFloat(26 / 62500),
  Subheading: parseFloat(20 / 62500),
  Body: parseFloat(18 / 62500)
}

export const fontCases = {
  Lower: 1,
  Upper: 2,
  Capitalized: 3
}

export const scripts = {
  Subscripted: 1,
  Superscripted: 2
}

export const defaultTextBackgroundOptions = {
  padding: {
    top: 10,
    right: 10,
    bottom: 8,
    left: 10
  },
  cornerRadius: {
    topLeft: 0,
    topRight: 0,
    bottomLeft: 0,
    bottomRight: 0
  },
  skew: {
    skewX: 0,
    skewY: 0
  }
}

export const levels = {
  layer: 'layer',
  letter: 'letter',
  word: 'word',
  line: 'line'
}

export const fontWeightMappings = {
  thin: 100,
  'ultra light': 200,
  'extra light': 200,
  light: 300,
  regular: 400,
  medium: 500,
  'demi bold': 600,
  'semi bold': 600,
  bold: 700,
  'extra bold': 800,
  'super bold': 800,
  'ultra bold': 800,
  black: 900,
  heavy: 900
}

export const fontWeightNameMapping = {
  100: 'Hairline',
  200: 'Ultra Light',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'Semi Bold',
  700: 'Bold',
  800: 'Extra Bold',
  900: 'Black'
};

export const fontStyleMappings = {
  italic: 'italic',
  oblique: 'oblique',
  normal: 'normal',
  regular: 'normal'
}