import { OnboardingRoute } from './constants'

const OnboardingLayout = () => import(/* webpackChunkName: "onboardingLayout" */ 'Layouts/OnboardingLayout');
const SignupUser = () => import(/* webpackChunkName: "signupUser" */ 'Features/onboarding/components/SignupUser');
const StoreDetails = () => import(/* webpackChunkName: "storeDetails" */ 'Features/onboarding/components/StoreDetails');

export default [
  {
    name: OnboardingRoute.Signup.name,
    path: OnboardingRoute.Signup.path,
    redirect: OnboardingRoute.SignupUser.path,
    component: OnboardingLayout,
    children: [
      {
        name: OnboardingRoute.SignupUser.name,
        path: OnboardingRoute.SignupUser.path,
        component: SignupUser,
        meta: {
          requiresAuth: false
        }
      },
      {
        name: OnboardingRoute.SetupCreativeBuilder.name,
        path: OnboardingRoute.SetupCreativeBuilder.path,
        component: StoreDetails,
        meta: {
          requiresAuth: true
        }
      }
    ],
    meta: {
      title: 'message.signUp'
    }
  }
]