import { Categories, CategoryTypes, Flavor, Stores, StoreTypes } from 'Constants/storeTypes';
import { UserRoles } from 'Constants/userRoles';
import { AppService } from 'AppServices/appService';
const { getUserDivisions } = new AppService();
import Vue from 'vue';

const state = {
  roimaticConfiguration: null,
  freshChatRestoreId: null,
  hqFranchiseStores: null,
  userDivisions: null,
  isProductConfigModified: false
};

const getters = {
  roimaticConfiguration: state => state.roimaticConfiguration,
  roimaticCategory: state =>
    state.roimaticConfiguration
      ? state.roimaticConfiguration.category
      : 'e-commerce',
  roimaticFlavour: state =>
    state.roimaticConfiguration
      ? state.roimaticConfiguration.flavor
      : 'shopify',
  deltaXFeedStatus: state =>
    state.roimaticConfiguration.productFeed
      ? state.roimaticConfiguration.productFeed.processingInfo
        .deltaXFeedStatus
      : null,
  isForCreativeBuilder: state =>
    state.roimaticConfiguration &&
        state.roimaticConfiguration.category ==
            CategoryTypes.find(a => a.id === Categories.CreativeBuilder)
              .category,
  isForRichMediaAdBuilder: state =>
    state.roimaticConfiguration &&
      state.roimaticConfiguration.category ==
          CategoryTypes.find(a => a.id === Categories.RichMediaAdBuilder)
            .category,
  freshChatRestoreId: state =>
    state.roimaticConfiguration
      ? state.roimaticConfiguration.freshChatRestoreId
      : null,
  isEcommerce: state => Boolean(state.roimaticConfiguration && state.roimaticConfiguration.category === CategoryTypes.find(ct => ct.id === Categories.ECommerce).category),
  isLocalBusiness: state => Boolean(state.roimaticConfiguration && state.roimaticConfiguration.category === CategoryTypes.find(ct => ct.id === Categories.LocalBusiness).category),
  isHqFranchise: state => Boolean(state.roimaticConfiguration && state.roimaticConfiguration.category === CategoryTypes.find(ct => ct.id === Categories.HqFranchise).category),
  isAutoFlavour: (state, getters) => getters.isHqFranchise && getters.roimaticFlavour == Flavor.auto,
  isCoPromote: (state) => Boolean(state.roimaticConfiguration && state.roimaticConfiguration.flavor === StoreTypes.find(s => s.id === Stores.CoPromote).store),
  hqFranchiseStores: state =>state.hqFranchiseStores,
  dealerGroups: state => {
    if (state.hqFranchiseStores && state.hqFranchiseStores.length > 0)
    {
      let storesHavingDealerGroup = state.hqFranchiseStores.filter(s => s.dealerGroupId && s.dealerGroupId.length > 0);
      let dealerGroupIds = storesHavingDealerGroup.map(s => s.dealerGroupId);
      dealerGroupIds = Array.from(new Set(dealerGroupIds));
      return dealerGroupIds;
    }
    return [];
  },
  isBrandPortal: state => Boolean(state.roimaticConfiguration && state.roimaticConfiguration.category === CategoryTypes.find(ct => ct.id === Categories.BrandPortal).category),
  userDivisions: state => state.userDivisions,
  isProductConfigModified: state=>state.isProductConfigModified,
  isForAffiliate: state =>
    state.roimaticConfiguration &&
      state.roimaticConfiguration.category ==
        CategoryTypes.find(a => a.id === Categories.Affiliate)
          .category
};

const actions = {
  setRoimaticConfiguration(context, payload) {
    context.commit('setRoimaticConfiguration', payload);
  },
  setHqFranchiseStores(context, payload){
    context.commit('setHqFranchiseStores', payload);
  },
  setStoreInsights(context, payload) {
    context.commit('setStoreInsights', payload);
  },
  async setUserDivisions(context){
    let user = Vue.$cookies.get('user');
    let portal = JSON.parse(localStorage.getItem('portal'));
    if(user.roleIds && !user.roleIds.includes(UserRoles.BrandManager)){
      // if user is not brand manager then don't fetch any divisions
      context.commit('setUserDivisions', []);
      return;
    }
    try{
      let divisions = await getUserDivisions(user.id, portal.id);
      context.commit('setUserDivisions', divisions);
    }catch(error){
      context.commit('setUserDivisions', []);
    }
  }
};

const mutations = {
  setRoimaticConfiguration(state, payload) {
    state.roimaticConfiguration = payload;
  },
  setHqFranchiseStores(state, payload){
    state.hqFranchiseStores = payload;
  },
  setStoreInsights(state, payload){
    payload.forEach(insight => {
      state.hqFranchiseStores.find(e => e.id == insight.storeId).cost = insight.insights.metricInsights[0].value;
      state.hqFranchiseStores.find(e => e.id == insight.storeId).costToBeSpent = insight.insights.metricInsights[1].value;
    });;
  },
  setUserDivisions(state, payload){
    state.userDivisions = payload;
  },
  productConfigModified(state, isProductConfigModified){
    state.isProductConfigModified = isProductConfigModified;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
