import Vue from 'vue';
import Router from 'vue-router';

//routes
import OnboardingRoutes from 'Features/onboarding/router.js';
import { AuthenticationRoute } from 'Features/login/router.js';
import CreativeBuilderRoutes from 'Features/creativeBuilder/router.js';
import { PageNotFoundRoute } from './pageNotFound';
import CbRendererRoute from 'Features/cbRenderer/router.js'

const RootLayout = () =>
  import(/* webpackChunkName: "rootLayout" */ 'Layouts/RootLayout');
const AppLayout = () =>
  import(/* webpackChunkName: "appLayout" */ 'Layouts/AppLayout');
import { ForgotPasswordRoutes } from 'Features/forgotPassword/router';
import { UserEmailVerificationRoute } from 'Features/userEmailVerification/router';
import { HomeRoute } from 'Features/home/router.js';
import { EmbedRoutes } from 'Features/embed/router'

const appRoutes = [
  ...CreativeBuilderRoutes
];

const rootLevelRoutes = [CbRendererRoute, PageNotFoundRoute];

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: RootLayout,
      redirect: '/login',
      props: (route) => ({ embed: String(route.query.embed).toLowerCase() === 'true' }),
      children: [
        ...OnboardingRoutes,
        HomeRoute,
        {
          path: '/app/:businessProfileId',
          component: AppLayout,
          redirect: '/app/:businessProfileId/creativeBuilder',
          props: (route) => ({ embed: String(route.query.embed).toLowerCase() === 'true' }),
          children: appRoutes
        },
        AuthenticationRoute,
        ...ForgotPasswordRoutes,
        UserEmailVerificationRoute,
        ...EmbedRoutes
      ]
    },
    {
      path: '/',
      component: RootLayout,
      children: rootLevelRoutes
    }
  ]
});