// Logo sizes should be 300x90 and icon size should be 512x512 in png format!!!!!
export const StoreTypes = [
  {
    id: 1,
    store: 'Shopify',
    logo: 'shopify-logo.png',
    icon: 'shopify-icon.png'
  },
  {
    id: 2,
    store: 'Magento',
    logo: 'magento-logo.png',
    icon: 'magento-icon.png'
  },
  {
    id: 3,
    store: 'Dominos',
    logo: 'dominos-logo.png',
    icon: 'dominos-icon.png'
  },
  {
    id: 4,
    store: 'Toyota',
    logo: 'toyota-logo.png',
    icon: 'toyota-icon.png'
  },
  {
    id: 5,
    store: 'local-business',
    logo: '',
    icon: ''
  },
  {
    id: 6,
    store: 'WooCommerce',
    logo: 'woocommerce-logo.png',
    icon: 'woocommerce-icon.png'
  },
  {
    id: 9,
    store: 'Goby',
    logo: '',
    icon: ''
  },
  {
    id: 7,
    store: 'BigCommerce',
    logo: 'big-commerce-logo.png',
    icon: 'big-commerce-logo.png'
  },
  {
    id: 10,
    store: 'DeltaX',
    logo: '',
    icon: ''
  },
  {
    id: 11,
    store: 'Auto',
    logo: '',
    icon: ''
  },
  {
    id: 12,
    store: 'CoPromote',
    logo: '',
    icon: ''
  },
  {
    id: 13,
    store: 'BrandPortal',
    logo: '',
    icon: ''
  },
  {
    id: 14,
    store: 'RichMediaAdBuilder',
    logo: '',
    icon: ''
  },
  {
    id: 8,
    store: 'CreativeBuilder',
    logo: '',
    icon: ''
  },
  {
    id: 15,
    store: 'Affiliate',
    logo: '',
    icon: ''
  },
  {
    id: 16,
    store: 'EdTech',
    logo: 'edTech-logo.png',
    icon: 'edTech-icon.png'
  }

]
export const Stores = {
  Shopify: 1,
  Magento: 2,
  Dominos: 3,
  Toyota: 4,
  LocalBusiness: 5,
  WooCommerce: 6,
  BigCommerce: 7,
  CreativeBuilder: 8,
  Goby: 9,
  deltaX: 10,
  Auto: 11,
  CoPromote: 12,
  BrandPortal: 13,
  RichMediaAdBuilder: 14,
  Affiliate: 15,
  EdTech: 16
}

export const Outlet = 'roimatic.adbox.pro';

export const Flavor = {
  creativeBuilder: 'CreativeBuilder',
  deltaX: 'DeltaX',
  goby: 'Goby',
  auto: 'Auto',
  brandPortal: 'BrandPortal',
  richMediaAdBuilder: 'RichMediaAdBuilder',
  shopify: 'Shopify',
  affiliate: 'Affiliate'
}

export const DeltaXMode = {
  campaignLauncher: 'CampaignLauncher',
  adServer: 'AdServer'
}

export const CategoryTypes = [
  {
    id: 1,
    category: 'local-business'
  },
  {
    id: 2,
    category: 'e-commerce'
  },
  {
    id: 3,
    category: 'hq-franchise'
  },
  {
    id: 4,
    category: 'creative-builder'
  },
  {
    id: 5,
    category: 'brand-portal'
  },
  {
    id: 6,
    category: 'rich-media-ad-builder'
  },
  {
    id: 7,
    category: 'affiliate'
  }
]
export const Categories = {
  LocalBusiness: 1,
  ECommerce: 2,
  HqFranchise: 3,
  CreativeBuilder: 4,
  BrandPortal: 5,
  RichMediaAdBuilder: 6,
  Affiliate: 7
}
