
const Login = () => import(/* webpackChunkName: "loginUser" */ 'Features/login/components/LoginUser')
const ProfileSelector = () => import(/* webpackChunkName: "profileSelector" */ 'Features/login/components/ProfileSelector')
const Authentication = () => import(/* webpackChunkName: "authentication" */ 'Features/login/Main')

const loginRoute =
{
  name: 'login',
  path: '/login',
  component: Login,
  meta: {
    requiresAuth: false,
    title: 'message.login'
  }
};

const profileSelectorRoute =
{
  name: 'profileSelection',
  path: '/profileSelection',
  component: ProfileSelector,
  meta: {
    requiresAuth: true,
    title: 'message.login'
  },
  beforeEnter: (to, from, next) => {
    window.location.href = process.env.VUE_APP_PROFILE_SELECTION_URL;
  }
};

export const authenticationRoute = {
  name: 'authentication',
  path: '/authentication',
  component: Authentication,
  children: [
    loginRoute,
    profileSelectorRoute
  ]
}

export const LoginRoute = loginRoute;
export const ProfileSelectorRoute = profileSelectorRoute;
export const AuthenticationRoute = authenticationRoute;